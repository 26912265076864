import React from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate';
import { parseSEK } from '../utils/parseSEK';
import Button from './Button';
import Card from './Card';
import DotsDropdown from './DotsDropdown';

const StyledOfferCard = styled.div``;

const OfferCard = ({
  entry,
  deleteEntryInit,
  editEntryInit,
  sendEntryInit,
  downloadPdf,
  previewPdf,
  duplicateEntry,
}) => {
  const totalSum = () => {
    const rowSums = entry.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  };
  return (
    <StyledOfferCard>
      <Card className="order-grid">
        <p className="mobile">{entry.offerNumber}</p>
        <p>{parseDate(entry.offerDate)}</p>
        <p>{entry.table.length}</p>
        <p>{parseSEK(totalSum())}</p>
        <p className="mobile">{entry.status}</p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => previewPdf(entry)}>
                Förhandsvisa
              </Button>
              <Button small onClick={() => downloadPdf(entry)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editEntryInit(entry)}>
                Redigera
              </Button>
              <Button small onClick={() => duplicateEntry(entry)}>
                Duplicera
              </Button>
              <Button small onClick={() => sendEntryInit(entry)}>
                Skicka som e-post
              </Button>
              <Button
                small
                onClick={() => deleteEntryInit(entry)}
                look="danger"
              >
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </StyledOfferCard>
  );
};

export default OfferCard;
