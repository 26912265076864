import React from 'react';
import DocumentPage from '../components/DocumentPage';
import OfferCard from '../components/OfferCard';

const context = {
  documentName: 'Offert',
  documentNameSpecific: 'Offerten',
  documentKey: 'offer',
  documentKeyPlural: 'offers',
  documentNamePlural: 'Offerter',
  internalPersonKey: 'sellerReferences',
  contactPersonKey: 'customerReferences',
  pdfNeedsFullCustomer: true,
  enablePdfUpload: true,
  documentIdentifier: 'offerNumber',
  cardComponent: OfferCard,
  internalPersonKeyRequired: true,
  cardGridTemplate: '1fr 1fr 1fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Offertnummer',
      mobile: true,
    },
    {
      text: 'Offertdatum',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Summa',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const OffersPage = ({ location }) => {
  const props = { location, context };
  return <DocumentPage {...props} />;
};

export default OffersPage;
